var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isPlatformServer } from '@angular/common';
import { environment } from '@env/environment';
import { Action, Selector, State } from '@ngxs/store';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { Init } from '@store/session/session.actions';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
export var SessionStateEnum;
(function (SessionStateEnum) {
    SessionStateEnum["Init"] = "INIT";
    SessionStateEnum["Initialized"] = "INITIALIZED";
})(SessionStateEnum || (SessionStateEnum = {}));
export class SessionStateModel {
}
const defaultState = {
    loading: false,
    state: SessionStateEnum.Init,
    error: null,
    errorMessage: null,
};
const defaultCompany = 'berger-diffusion';
let SessionState = class SessionState {
    constructor(platformId, window, request) {
        this.platformId = platformId;
        this.window = window;
        this.request = request;
    }
    // tslint:disable:no-identical-functions
    static state(state) {
        return state.state;
    }
    static isInitialized(state) {
        return state.state !== SessionStateEnum.Init;
    }
    static loading(state) {
        return state.loading;
    }
    static error(state) {
        return state.error;
    }
    static errorMessage(state) {
        return state.errorMessage;
    }
    init(ctx) {
        let companyId = defaultCompany;
        if (isPlatformServer(this.platformId) && this.request) {
            companyId = this.request.companyId || defaultCompany;
        }
        else if (!environment.dev) {
            companyId = this.window.location.hostname.split('.')[0];
        }
        return ctx
            .dispatch([new SetAuthenticatedCompany(companyId)])
            .pipe(switchMap(() => of(ctx.patchState({ state: SessionStateEnum.Initialized }))));
    }
};
__decorate([
    Action(Init),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SessionState.prototype, "init", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "state", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "isInitialized", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "loading", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "error", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [SessionStateModel]),
    __metadata("design:returntype", void 0)
], SessionState, "errorMessage", null);
SessionState = __decorate([
    State({
        name: 'session',
        defaults: defaultState,
    }),
    __metadata("design:paramtypes", [Object, Object, Object])
], SessionState);
export { SessionState };
