import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Inject, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router } from '@angular/router';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { trackingConfig } from '@config/tracking-config';
import { MainComponent } from '@core/components/main/main.component';
import { SpriteComponent } from '@core/components/sprite/sprite.component';
import { CoreRoutingModule } from '@core/core-routing.module';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { ScrollLockService } from '@core/services/scroll-lock.service';
import { environment } from '@env/environment';
import { FeaturesModule } from '@features/features.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { CompaniesState } from '@store/companies/companies.state';
import { SetConsents } from '@store/consents/consents.actions';
import { ScrollLockState } from '@store/scroll-lock/scroll-lock.state';
import { SessionState } from '@store/session/session.state';
import { AppBrowserViewportScroller, ImageService, WINDOW, WINDOW_PROVIDERS } from '@wizbii/angular-utilities';
import { ScrollToModule } from '@wizbii/nicky-lenaers_ngx-scroll-to';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';
import { setupTracking, track } from '@wizbii/tracking';
import { CookieService } from 'ngx-cookie-service';
import { from } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: environment.applicationId }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    FeaturesModule,
    CoreRoutingModule,
    HeadTitleModule,
    HeadDescriptionModule,

    NgxsModule.forRoot([ScrollLockState, SessionState, CompaniesState], {
      developmentMode: environment.dev,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Atsii Career',
      maxAge: 30,
    }),

    TrackerModule.forRoot(),
    ScrollToModule.forRoot(),
  ],
  declarations: [MainComponent, SpriteComponent],
  providers: [
    WINDOW_PROVIDERS,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: RouterStateSerializer,
      useClass: WizbiiRouterStateSerializer,
    },
    ScrollLockService,
    CookieService,

    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },

    {
      provide: ViewportScroller,
      useClass: AppBrowserViewportScroller,
    },
  ],
  bootstrap: [MainComponent],
  exports: [MainComponent],
})
export class CoreModule {
  constructor(
    router: Router,
    ngZone: NgZone,
    store: Store,
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(WINDOW) window: any
  ) {
    ngZone.runOutsideAngular(() => {
      if (isPlatformBrowser(platformId)) {
        this.initializeTracking(router, window, store);
      }
    });
  }

  private initializeTracking(router: Router, window: Window, store: Store) {
    setupTracking(trackingConfig).then(() => {
      from(window.WizbiiGdpr.getConsents())
        .pipe(
          tap((consents: any) => store.dispatch(new SetConsents(consents))),
          switchMap(() => router.events),
          filter(event => event instanceof NavigationEnd),
          map(event => (event as NavigationEnd).urlAfterRedirects),
          startWith(window.location.href.replace(window.origin, '')),
          map(page => ({ url: window.location.href, page }))
        )
        .subscribe((event: any) => {
          track('pageview', event);
        });
    });
  }
}
