import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly routerExtension: RouterExtensionService,
    @Optional() @Inject(RESPONSE) private readonly response: any
  ) {}

  ngOnInit() {
    if (this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = 'Not Found';
    } else if (this.route.snapshot.data.unhandled404 && this.routerExtension.previousUrl) {
      throw new Error('Unhandled 404');
    }
  }
}
