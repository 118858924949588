var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var CompaniesState_1;
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetCompany, GetCompanySuccess, HandleErrors, SetAuthenticatedCompany, } from '@store/companies/companies.actions';
import { ImageService } from '@wizbii/angular-utilities';
import { CompanyWebservice } from '@wizbii/webservices';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
export class CompaniesStateModel {
}
const defaultState = {
    authenticatedCompanyId: null,
    companies: {},
    error: null,
};
let CompaniesState = CompaniesState_1 = class CompaniesState {
    constructor(companyWebservice, imageService, response) {
        this.companyWebservice = companyWebservice;
        this.imageService = imageService;
        this.response = response;
    }
    static company(companyId) {
        return createSelector([CompaniesState_1], (state) => {
            return state.companies[companyId];
        });
    }
    static authenticatedCompany(state) {
        return state.companies[state.authenticatedCompanyId];
    }
    static authenticatedCompanyId(state) {
        return state.authenticatedCompanyId;
    }
    getCompany(ctx, { id, silence404 }) {
        const { companies } = ctx.getState();
        if (!companies[id]) {
            return this.companyWebservice.get(id).pipe(switchMap(company => ctx.dispatch(new GetCompanySuccess(company))), silence404 // ADR #006
                ? catchError((err) => {
                    ErrorHandlerService.silence(err, err instanceof HttpErrorResponse && err.status === 404);
                    return throwError(err);
                })
                : catchError(error => ctx.dispatch(new HandleErrors(error))));
        }
    }
    getCompanySuccess(ctx, { company }) {
        if (this.response && company.logo && company.logo !== '') {
            const logoUrl = `${environment.api.googleStorage}/${environment.wizbiiFiles}/${company.logo}`;
            this.response.setHeader('X-Company-Imaginary-Logo-Url', this.imageService.resize(logoUrl, { width: 42 }));
            this.response.setHeader('X-Company-Logo-Url', logoUrl);
        }
        return ctx.setState(patch({
            companies: patch({
                [company._id]: company,
            }),
        }));
    }
    setAuthenticatedCompany(ctx, { id }) {
        ctx.patchState({ authenticatedCompanyId: id });
        return ctx.dispatch(new GetCompany(id, false));
    }
    handleErrors(ctx, { error }) {
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Company doesn't exist so => 404
            case 404: {
                console.error('Not found [Companies]');
                ctx.patchState({ error });
                return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error(`Code ${error.status} => ${error.statusText}`);
                return ctx.patchState({ error });
            }
        }
        // tslint:enable no-small-switch
    }
};
__decorate([
    Action(GetCompany),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, GetCompany]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "getCompany", null);
__decorate([
    Action(GetCompanySuccess),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, GetCompanySuccess]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "getCompanySuccess", null);
__decorate([
    Action(SetAuthenticatedCompany),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetAuthenticatedCompany]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "setAuthenticatedCompany", null);
__decorate([
    Action(HandleErrors),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, HandleErrors]),
    __metadata("design:returntype", void 0)
], CompaniesState.prototype, "handleErrors", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [CompaniesStateModel]),
    __metadata("design:returntype", void 0)
], CompaniesState, "authenticatedCompany", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [CompaniesStateModel]),
    __metadata("design:returntype", void 0)
], CompaniesState, "authenticatedCompanyId", null);
CompaniesState = CompaniesState_1 = __decorate([
    State({
        name: 'companies',
        defaults: defaultState,
    }),
    __metadata("design:paramtypes", [CompanyWebservice,
        ImageService, Object])
], CompaniesState);
export { CompaniesState };
