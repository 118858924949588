import { Routes } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';
const ɵ0 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory), ɵ1 = () => import("./job/job.module.ngfactory").then(m => m.JobModuleNgFactory), ɵ2 = { unhandled404: true };
const routes = [
    {
        path: FeaturesRoutingEnum.Home,
        loadChildren: ɵ0,
    },
    {
        path: `${FeaturesRoutingEnum.Job}/${FeaturesRoutingEnum.JobId}`,
        loadChildren: ɵ1,
    },
    {
        path: FeaturesRoutingEnum.NotFound,
        component: NotFoundComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
        pathMatch: 'full',
        data: ɵ2,
    },
];
export class FeaturesRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
