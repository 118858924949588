<footer class="footer">
  <div class="footer__content">
    <span class="footer__text" i18n>Recrutement avec</span>
    <a
      [href]="atsiiUrl"
      class="footer__link"
      target="_blank"
      rel="noopener"
      appEventTracker
      [eventCategory]="location"
      eventAction="Click Ats Logo"
    >
      <svg-icon class="footer__logo" icon="atsii" label="Atsii" width="3.1875em" height="1.1806em"></svg-icon>
    </a>
  </div>
</footer>
