import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WizFile } from '@wizbii/models';
import { FileWebservice } from '@wizbii/webservices';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class FileService {
  private readonly uploadPercent = new BehaviorSubject<number>(0);
  uploadPercent$: Observable<number> = this.uploadPercent;

  private readonly uploading = new BehaviorSubject<boolean>(false);
  uploading$: Observable<boolean> = this.uploading;

  constructor(private readonly fileWebservice: FileWebservice) {}

  create(file: WizFile): Observable<WizFile> {
    return this.fileWebservice.create(file).pipe(
      tap((e: HttpEvent<any>) => this.setProgress(e)),
      filter((e: HttpEvent<any>) => e.type === HttpEventType.Response),
      map((e: HttpResponse<WizFile>) => e.body)
    );
  }

  setProgress(event: HttpEvent<any>): void {
    switch (event.type) {
      case HttpEventType.Sent:
        this.uploadPercent.next(0);
        this.uploading.next(true);
        break;
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        this.uploadPercent.next(Math.round((100 * event.loaded) / event.total));
        break;
      case HttpEventType.Response:
        this.uploadPercent.next(100);
        this.uploading.next(false);
        break;
      default:
        break;
    }
  }
}
