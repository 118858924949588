/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tracker/event-tracker/event-tracker.directive";
import * as i3 from "../tracker/event-tracker/event-tracker.service";
import * as i4 from "../svg-icon/svg-icon.component.ngfactory";
import * as i5 from "../svg-icon/svg-icon.component";
import * as i6 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "footer__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "footer__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recrutement avec"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["appEventTracker", ""], ["class", "footer__link"], ["eventAction", "Click Ats Logo"], ["rel", "noopener"], ["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).doClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.EventTrackerDirective, [i3.EventTrackerService], { eventCategory: [0, "eventCategory"], eventAction: [1, "eventAction"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svg-icon", [["class", "footer__logo"], ["height", "1.1806em"], ["icon", "atsii"], ["label", "Atsii"], ["width", "3.1875em"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(7, 49152, null, 0, i5.SvgIconComponent, [], { icon: [0, "icon"], label: [1, "label"], width: [2, "width"], height: [3, "height"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.location; var currVal_2 = "Click Ats Logo"; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = "atsii"; var currVal_4 = "Atsii"; var currVal_5 = "3.1875em"; var currVal_6 = "1.1806em"; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.atsiiUrl; _ck(_v, 4, 0, currVal_0); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i6.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i6.FooterComponent, View_FooterComponent_Host_0, { location: "location" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
