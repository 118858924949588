import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Optional } from '@angular/core';
import { Company } from '@ats/models';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  GetCompany,
  GetCompanySuccess,
  HandleErrors,
  SetAuthenticatedCompany,
} from '@store/companies/companies.actions';
import { ImageService } from '@wizbii/angular-utilities';
import { CompanyWebservice } from '@wizbii/webservices';
import { Response } from 'express';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class CompaniesStateModel {
  authenticatedCompanyId: string;
  companies: Record<string, Company>;

  error: any;
}

const defaultState: CompaniesStateModel = {
  authenticatedCompanyId: null,
  companies: {},

  error: null,
};

@State<CompaniesStateModel>({
  name: 'companies',
  defaults: defaultState,
})
export class CompaniesState {
  static company(companyId: string) {
    return createSelector(
      [CompaniesState],
      (state: CompaniesStateModel) => {
        return state.companies[companyId];
      }
    );
  }

  @Selector()
  static authenticatedCompany(state: CompaniesStateModel) {
    return state.companies[state.authenticatedCompanyId];
  }

  @Selector()
  static authenticatedCompanyId(state: CompaniesStateModel) {
    return state.authenticatedCompanyId;
  }
  constructor(
    private readonly companyWebservice: CompanyWebservice,
    private readonly imageService: ImageService,
    @Optional() @Inject(RESPONSE) private readonly response?: Response
  ) {}

  @Action(GetCompany)
  getCompany(ctx: StateContext<CompaniesStateModel>, { id, silence404 }: GetCompany) {
    const { companies } = ctx.getState();

    if (!companies[id]) {
      return this.companyWebservice.get(id).pipe(
        switchMap(company => ctx.dispatch(new GetCompanySuccess(company))),
        silence404 // ADR #006
          ? catchError((err: HttpErrorResponse) => {
              ErrorHandlerService.silence(err, err instanceof HttpErrorResponse && err.status === 404);
              return throwError(err);
            })
          : catchError(error => ctx.dispatch(new HandleErrors(error)))
      );
    }
  }

  @Action(GetCompanySuccess)
  getCompanySuccess(ctx: StateContext<CompaniesStateModel>, { company }: GetCompanySuccess) {
    if (this.response && company.logo && company.logo !== '') {
      const logoUrl = `${environment.api.googleStorage}/${environment.wizbiiFiles}/${company.logo}`;
      this.response.setHeader('X-Company-Imaginary-Logo-Url', this.imageService.resize(logoUrl, { width: 42 }));
      this.response.setHeader('X-Company-Logo-Url', logoUrl);
    }

    return ctx.setState(
      patch<CompaniesStateModel>({
        companies: patch({
          [company._id]: company,
        }),
      })
    );
  }

  @Action(SetAuthenticatedCompany)
  setAuthenticatedCompany(ctx: StateContext<CompaniesStateModel>, { id }: SetAuthenticatedCompany) {
    ctx.patchState({ authenticatedCompanyId: id });
    return ctx.dispatch(new GetCompany(id, false));
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<CompaniesStateModel>, { error }: HandleErrors) {
    // tslint:disable no-small-switch
    switch (error.status) {
      // The Company doesn't exist so => 404
      case 404: {
        console.error('Not found [Companies]');

        ctx.patchState({ error });
        return ctx.dispatch(new Navigate(['/', FeaturesRoutingEnum.NotFound], undefined, { skipLocationChange: true }));
      }

      default: {
        console.error(`Code ${error.status} => ${error.statusText}`);
        return ctx.patchState({ error });
      }
    }
    // tslint:enable no-small-switch
  }
}
