import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  HostBinding,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { RouterExtensionService } from '@core/services/router-extension.service';
import { Store } from '@ngxs/store';
import { CompaniesState } from '@store/companies/companies.state';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled() {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  // Initialized globaly to watch all router events
  constructor(
    store: Store,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(LOCALE_ID) private readonly localeId: string,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(ErrorHandler) errorHandlerService: ErrorHandlerService,
    _: RouterExtensionService // initialise globally to watch all router events,
  ) {
    if (isPlatformServer(platformId)) {
      return;
    }

    // Provide company data to Bugsnag
    store.selectOnce(CompaniesState.authenticatedCompany).subscribe(company => {
      if (!errorHandlerService.bugsnagClient.metaData) {
        errorHandlerService.bugsnagClient.metaData = {
          company: company && { ...company },
        };
      } else {
        errorHandlerService.bugsnagClient.metaData['company'] = company && { ...company };
      }
    });
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event) {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  /**
   * Disable hover on `touchstart` to cover browsers that do not support pointer events.
   * https://caniuse.com/#feat=pointer
   */
  @HostListener('touchstart')
  onTouchStart() {
    this.hoverEnabled = false;
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown() {
    this.focusOutlineDisabled = false;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && this.document.documentElement.lang === '') {
      this.setLanguageInDom(this.localeId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setLanguageInDom(language: string): void {
    this.document.documentElement.lang = language;
  }
}
