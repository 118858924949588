import { isPlatformBrowser } from '@angular/common';
import { NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trackingConfig } from '@config/tracking-config';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { SetConsents } from '@store/consents/consents.actions';
import { ImageService } from '@wizbii/angular-utilities';
import { setupTracking, track } from '@wizbii/tracking';
import { from } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
const ɵ0 = new ImageService(environment.api.imaginary);
export class CoreModule {
    constructor(router, ngZone, store, platformId, window) {
        ngZone.runOutsideAngular(() => {
            if (isPlatformBrowser(platformId)) {
                this.initializeTracking(router, window, store);
            }
        });
    }
    initializeTracking(router, window, store) {
        setupTracking(trackingConfig).then(() => {
            from(window.WizbiiGdpr.getConsents())
                .pipe(tap((consents) => store.dispatch(new SetConsents(consents))), switchMap(() => router.events), filter(event => event instanceof NavigationEnd), map(event => event.urlAfterRedirects), startWith(window.location.href.replace(window.origin, '')), map(page => ({ url: window.location.href, page })))
                .subscribe((event) => {
                track('pageview', event);
            });
        });
    }
}
export { ɵ0 };
