/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../sprite/sprite.component.ngfactory";
import * as i4 from "../sprite/sprite.component";
import * as i5 from "./main.component";
import * as i6 from "@ngxs/store";
import * as i7 from "@angular/common";
import * as i8 from "../../services/router-extension.service";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
export function View_MainComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-sprite", [], null, null, null, i3.View_SpriteComponent_0, i3.RenderType_SpriteComponent)), i1.ɵdid(3, 49152, null, 0, i4.SpriteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], [[2, "hover-on", null], [2, "hover-off", null], [2, "focus-off", null]], [[null, "pointerenter"], [null, "touchstart"], [null, "mousedown"], [null, "keydown.Tab"]], function (_v, en, $event) { var ad = true; if (("pointerenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPointerEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouchStart() !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onMouseDown() !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Tab" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onTabKeyDown() !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 245760, null, 0, i5.MainComponent, [i6.Store, i7.DOCUMENT, i1.LOCALE_ID, i1.PLATFORM_ID, i1.ErrorHandler, i8.RouterExtensionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hoverEnabled; var currVal_1 = i1.ɵnov(_v, 1).hoverDisabled; var currVal_2 = i1.ɵnov(_v, 1).focusOutlineDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var MainComponentNgFactory = i1.ɵccf("app-main", i5.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
