import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrackerModule } from '@commons/tracker/tracker.module';
import { InitGuard } from '@core/guards/init.guard';
import { FileService } from '@core/services/file.service';
import { environment } from '@env/environment';
import { FeaturesRoutingModule } from '@features/features-routing.module';
import { NotFoundModule } from '@features/not-found/not-found.module';
import { CandidateApplicationWebservice } from '@webservices/candidate-api/candidate-application.webservice';
import { JobWebservice } from '@webservices/job-api/job.webservice';
import { HeadDescriptionModule, HeadTitleModule } from '@wizbii/seo-lib';
import {
  ApplicationWebservice,
  CANDIDATE_API_CONFIG,
  CompanyWebservice,
  FILE_API_CONFIG,
  FileWebservice,
  RECRUITER_API_CONFIG,
} from '@wizbii/webservices';

@NgModule({
  imports: [CommonModule, FeaturesRoutingModule, NotFoundModule, HeadTitleModule, HeadDescriptionModule, TrackerModule],
  providers: [
    {
      provide: RECRUITER_API_CONFIG,
      useValue: {
        baseUrl: environment.api.recruiter,
      },
    },
    CompanyWebservice,
    {
      provide: CANDIDATE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.candidate,
      },
    },
    ApplicationWebservice,
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    FileWebservice,
    FileService,
    JobWebservice,

    InitGuard,

    CandidateApplicationWebservice,
  ],
})
export class FeaturesModule {}
