import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Job, toJob } from '@ats/models';
import { environment } from '@env/environment';
import { buildUrl } from '@wizbii/angular-utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JobWebservice {
  private readonly baseUrl = `${environment.api.job}/v1/jobs/?:id`;

  constructor(private readonly http: HttpClient) {}

  get(id: string): Observable<Job> {
    const url = buildUrl(this.baseUrl, { id });

    return this.http.get<object>(url).pipe(map(toJob));
  }

  find(companyId: string): Observable<Job[]> {
    const url = buildUrl(this.baseUrl, { companyId });

    return this.http.get<object[]>(url).pipe(map(jobs => jobs.map(toJob)));
  }

  getDomains(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/domains`);
  }

  getContracts(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/contracts`);
  }

  getDurations(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/durations`);
  }

  getStudyLevels(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/study-levels`);
  }

  getExperiences(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/experiences`);
  }

  getStatus(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.job}/v1/status`);
  }
}
