import { HttpErrorResponse } from '@angular/common/http';
import { Application, Utm } from '@ats/models';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { HandleErrors, InitStaticData, SetTmpApplication } from '@store/applications/applications.actions';
import { buildUrl } from '@wizbii/angular-utilities';
import { ApplicationWebservice } from '@wizbii/webservices';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class ApplicationStateModel {
  sourcesPlatforms: { name: string; utm: Utm }[];
  tmpApplication?: Application;

  error: any;
}

const defaultState: ApplicationStateModel = {
  sourcesPlatforms: null,
  tmpApplication: null,

  error: null,
};

@State<ApplicationStateModel>({
  name: 'applications',
  defaults: defaultState,
})
export class ApplicationsState {
  @Selector()
  static sourcesPlatforms(state: ApplicationStateModel) {
    return state.sourcesPlatforms;
  }

  @Selector()
  static tmpApplication(state: ApplicationStateModel) {
    return state.tmpApplication;
  }

  static sourcePlatform(name: string) {
    return createSelector(
      [ApplicationsState],
      (state: ApplicationStateModel) =>
        state.sourcesPlatforms.find(source => name.toLowerCase().includes(source.utm.source.toLowerCase()))
    );
  }

  constructor(private readonly applicationWebservice: ApplicationWebservice) {}

  @Action(InitStaticData)
  initStaticData(ctx: StateContext<ApplicationStateModel>) {
    const { sourcesPlatforms } = ctx.getState();

    if (sourcesPlatforms && sourcesPlatforms.length > 0) {
      return undefined;
    }

    return this.applicationWebservice.getSourcesPlatform().pipe(
      map(_sourcesPlatforms =>
        of(
          ctx.patchState({
            sourcesPlatforms: _sourcesPlatforms,
          })
        )
      ),
      catchError((err: HttpErrorResponse | Error) => {
        if (err instanceof HttpErrorResponse && err.status === 500) {
          return throwError(new Error('Internal Server Error while getting datas'));
        }

        return throwError(err);
      })
    );
  }

  @Action(SetTmpApplication)
  setTmpApplication(ctx: StateContext<ApplicationStateModel>, { application }: SetTmpApplication) {
    ctx.patchState({
      tmpApplication:
        application && application.utmSource && application.utmSource.utm && application.utmSource.utm.source
          ? application
          : null,
    });
  }

  @Action(HandleErrors)
  handleErrors(ctx: StateContext<ApplicationStateModel>, { error }: HandleErrors) {
    // tslint:disable no-small-switch
    switch (error.status) {
      // The Job doesn't exist so => 404
      case 404: {
        console.error('Not found [Application]');

        ctx.patchState({ error });
        return ctx.dispatch(
          new Navigate([buildUrl(FeaturesRoutingEnum.NotFound)], undefined, { skipLocationChange: true })
        );
      }

      default: {
        console.error(`Code ${error.status} => ${error.statusText}`);
        return ctx.patchState({ error });
      }
    }
    // tslint:enable no-small-switch
  }
}
