import { version } from '@env/version';

let env: any = {};

if (typeof window === 'object') {
  env = window['env'] || {};
} else if (typeof global === 'object') {
  env = global['ANGULAR_ENV'] || {};
}

const platform: string = env.PLATFORM || 'prod';
const wizbiiDomain = env.WIZBII_DOMAIN || 'wizbii.com';
const atsDomain = env.ATS_DOMAIN || 'atsii.fr';
const atsPublicDomain = `www.${atsDomain}`;

export const environment = {
  applicationId: 'ats-career',
  version,
  wizbiiDomain,
  atsDomain,
  atsPublicDomain,
  bugsnagId: '85801b7aa3effc759cd877f98dbcf2ae',
  bugsnagSsrId: 'TODO',
  prod: platform === 'prod',
  qa3: platform === 'qa3',
  qa4: platform === 'qa4',
  dev: platform === 'dev',
  name: platform,
  atsiiUrl: `https://www.${atsDomain}`,
  api: {
    candidate: `https://candidate.api.${atsDomain}`,
    consent: `https://consent.${wizbiiDomain}`,
    contact: `https://contact.api.${wizbiiDomain}`,
    content: `https://content-api.${wizbiiDomain}`,
    file: `https://file.${wizbiiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${wizbiiDomain}`,
    images: `https://images.${wizbiiDomain}`,
    job: `https://job.api.${atsDomain}`,
    media: `https://m-api.${wizbiiDomain}`,
    place: `https://place.${wizbiiDomain}`,
    recruiter: `https://recruiter.api.${atsDomain}`,
    wizbiiAnalytiks: `https://a.${atsDomain}`,
  },
  wizbiiAnalytiksUserId: env.WIZBII_ANALYTICS_UAI || 'UA-22851031-40',
  googleAnalyticsUserId: env.GOOGLE_ANALYTICS_UAI || 'UA-22851031-40',
  wizbiiFiles: env.WIZBII_FILES_GCS_BUCKET || 'wizbii-files',
  partnersDomain: `https://p.${wizbiiDomain}`,
  hotjar: {
    id: env.HOTJAR_ID || '1258167',
    version: '6',
  },
  gdprWidgetUrl: `https://storage.googleapis.com/wizbii/consent/prod/widget/bundle.js`,
};
