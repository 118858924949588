<svg class="visually-hidden" xmlns="http://www.w3.org/2000/svg">
  <!-- ATSII logo -->
  <symbol id="icon-atsii" viewBox="0 0 80 28">
    <g fill="none" fill-rule="evenodd">
      <path
        fill="currentColor"
        d="M10.82 3.32L0 27.6h5.76l2.15-5.2H19.2l2.15 5.2h5.9L16.38 3.3h-5.56zm2.75 5.52l3.85 9.3h-7.7l3.85-9.3zM41.25 26.7c-.53.4-1.18.7-1.96.89-.77.2-1.59.3-2.44.3-2.22 0-3.94-.57-5.16-1.7-1.21-1.14-1.82-2.8-1.82-5v-7.67H27V9.36h2.88V4.8h5.41v4.55h4.65v4.16h-4.65v7.6c0 .78.2 1.4.61 1.82.4.43.98.64 1.72.64.85 0 1.58-.23 2.19-.7l1.45 3.82zM46.04 27.31a11.87 11.87 0 0 1-3.54-1.44L44.3 22c.84.53 1.84.96 3.02 1.3 1.18.33 2.34.5 3.47.5 2.3 0 3.44-.57 3.44-1.7 0-.53-.31-.91-.94-1.15-.62-.23-1.58-.42-2.88-.59-1.52-.23-2.79-.5-3.78-.8-1-.3-1.86-.83-2.58-1.59-.73-.76-1.1-1.85-1.1-3.26a5.1 5.1 0 0 1 1.03-3.14 6.59 6.59 0 0 1 2.98-2.13c1.3-.51 2.85-.77 4.63-.77 1.32 0 2.63.15 3.94.44 1.3.29 2.39.68 3.24 1.2l-1.8 3.84a10.77 10.77 0 0 0-5.38-1.38c-1.16 0-2.02.16-2.6.48-.58.33-.87.74-.87 1.25 0 .58.31.98.94 1.22.62.23 1.62.45 2.98.65 1.53.26 2.78.53 3.75.82.97.29 1.82.82 2.53 1.58a4.48 4.48 0 0 1 1.08 3.19c0 1.16-.35 2.19-1.04 3.09-.7.9-1.7 1.6-3.04 2.1-1.33.5-2.9.74-4.73.74-1.55 0-3.07-.19-4.55-.57"
      />
      <path
        fill="#8683FF"
        d="M73.62 8.63h5.41v19.2h-1.91a3.5 3.5 0 0 1-3.5-3.5V8.64zM79.6 3.38A3.21 3.21 0 0 0 76.33.23a3.21 3.21 0 0 0-3.27 3.15 3.21 3.21 0 0 0 3.27 3.15c1.8 0 3.27-1.4 3.27-3.15M63.35 8.63h5.41v19.2h-1.9a3.5 3.5 0 0 1-3.5-3.5V8.64zM69.33 3.38A3.21 3.21 0 0 0 66.06.23a3.21 3.21 0 0 0-3.26 3.15 3.21 3.21 0 0 0 3.26 3.15c1.8 0 3.27-1.4 3.27-3.15"
      />
    </g>
  </symbol>

  <!-- Experience icon set -->
  <symbol id="icon-exp-account" viewBox="0 0 18 24">
    <path
      d="M15 24H5a1 1 0 0 1 0-2h10a1 1 0 0 0 1-1v-4.15a1.63 1.63 0 0 0-.89-1.31 16.32 16.32 0 0 0-12.22 0A1.63 1.63 0 0 0 2 16.85V23a1 1 0 0 1-2 0v-6.15a3.59 3.59 0 0 1 2.14-3.17 18.31 18.31 0 0 1 13.72 0A3.59 3.59 0 0 1 18 16.85V21a3 3 0 0 1-3 3zM14 5a5 5 0 1 0-5 5 5 5 0 0 0 5-5zm-2 0a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"
    />
  </symbol>

  <symbol id="icon-exp-big-check" viewBox="0 0 90 90">
    <path
      d="M41.2 59.2c-.8.8-2 .8-2.8 0L26.6 47.5c-.8-.8-.8-2 0-2.8s2-.8 2.8 0l12 12c.6.5.6 1.7-.2 2.5zm3.4-5.7c-.8-.8-.8-2 0-2.8l18-18c.8-.8 2-.8 2.8 0s.8 2 0 2.8l-18 18c-.8.8-2 .8-2.8 0z"
    />
    <path
      d="M45 0C20.214 0 0 20.214 0 45s20.214 45 45 45c10.249 0 19.672-3.454 27.199-9.1a2 2 0 1 0-2.398-3.2C62.928 82.853 54.35 86 45 86 22.386 86 4 67.614 4 45S22.386 4 45 4s41 18.386 41 41c0 8.87-2.863 17.168-7.627 23.838a2 2 0 1 0 3.254 2.324A45.044 45.044 0 0 0 90 45C90 20.214 69.786 0 45 0z"
    />
  </symbol>

  <symbol id="icon-exp-briefcase" viewBox="0 0 24 21">
    <path
      d="M21 21H3a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v1.3a2 2 0 0 1-1.32 1.88l-9.6 3.49a3 3 0 0 1-2.21-.06l-6.26-2.69a1 1 0 0 1 .79-1.84l6.27 2.69a1 1 0 0 0 .73 0L22 8.3V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-5a1 1 0 0 1 2 0v5a3 3 0 0 1-3 3zM17 1a1 1 0 0 0-1-1H8a1 1 0 0 0 0 2h8a1 1 0 0 0 1-1z"
    />
  </symbol>

  <symbol id="icon-exp-calendar" viewBox="0 0 24 24">
    <path
      d="M21 24H3a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3 1 1 0 0 0 1-1V1a1 1 0 0 1 2 0v1a3 3 0 0 1-3 3 1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V4a1 1 0 0 1 2 0v17a3 3 0 0 1-3 3zM20 2V1a1 1 0 0 0-2 0v1a1 1 0 0 1-1 1H9a1 1 0 0 0 0 2h8a3 3 0 0 0 3-3zM6 9a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM6 13a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm8 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm-4 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm8 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM6 17a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 0a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
    />
  </symbol>

  <symbol id="icon-exp-certificate" viewBox="0 0 18 23.7">
    <path
      d="M12.5 23.77a1.49 1.49 0 0 1-.35 0l-2.65-.68a1.13 1.13 0 0 0-.73 0l-3 .65A1.5 1.5 0 0 1 4 22.25v-5.51a1.49 1.49 0 0 1 .69-1.26 1.48 1.48 0 0 1 1.42-.11 7 7 0 1 0-3.17-2.87 1 1 0 0 1-1.73 1 9 9 0 0 1-1.1-5.92A9 9 0 0 1 17.95 10a9 9 0 0 1-7.76 7.93A9.13 9.13 0 0 1 6 17.49v4.13l2.25-.49a2.93 2.93 0 0 1 1.81 0l1.94.46V20a1 1 0 0 1 2 0v2.27a1.5 1.5 0 0 1-1.5 1.5zM8.71 11.71l4-4A1 1 0 0 0 11.3 6.3L8 9.58 6.71 8.29a1 1 0 0 0-1.42 1.42l2 2a1 1 0 0 0 1.41 0z"
    />
  </symbol>

  <symbol id="icon-exp-chevron-down" viewBox="0 0 14 8">
    <path
      d="m 6.2553191,7.7037037 c -0.3971631,-0.2962963 -0.3971631,-0.8888889 0,-1.2839506 L 12.411348,0.2962963 c 0.397163,-0.39506173 0.893617,-0.39506173 1.29078,0 0.397163,0.39506172 0.397163,0.8888889 0,1.2839506 L 7.5460993,7.7037037 c -0.3971631,0.3950617 -0.893617,0.3950617 -1.2907802,0 z M 5.1631206,5.2345679 c -0.3971632,0.3950617 -0.9929078,0.3950617 -1.2907802,0 L 0.29787234,1.6790124 c -0.39716312,-0.3950618 -0.39716312,-0.88888895 0,-1.28395067 C 0.69503546,0 1.1914894,0 1.5886525,0.39506173 L 5.1631206,3.9506173 c 0.3971631,0.3950617 0.3971631,0.8888889 0,1.2839506 z"
    />
  </symbol>

  <symbol id="icon-exp-joystick" viewBox="0 0 23.19 17.49">
    <path
      d="M19.12 17.49a4.08 4.08 0 0 1-3.25-1.62l-.58-.78a1 1 0 1 1 1.6-1.2l.58.78a2.13 2.13 0 0 0 3.2.13 2 2 0 0 0 .5-1.62l-1-7.66A4 4 0 0 0 15.8 2l-3.55.39a6 6 0 0 1-1.33 0L7.38 2A4 4 0 0 0 3 5.5l-1 7.67a2 2 0 0 0 .5 1.62 2.13 2.13 0 0 0 3.2-.13l1.16-1.55a4.08 4.08 0 0 1 3.25-1.62h2.47a1 1 0 0 1 0 2h-2.46a2.07 2.07 0 0 0-1.65.83l-1.16 1.54A4.06 4.06 0 0 1 0 12.92l1-7.66A6 6 0 0 1 7.61 0l3.55.39a3.93 3.93 0 0 0 .88 0L15.58 0a6 6 0 0 1 6.62 5.26l1 7.66a4.06 4.06 0 0 1-4 4.57zM12.89 6.66a1 1 0 1 0 1.41 0 1 1 0 0 0-1.41 0zM15 4.78a1 1 0 1 0 1.41 0 1 1 0 0 0-1.41 0zm1.88 1.88a1 1 0 1 0 1.41 0 1 1 0 0 0-1.4 0zM15 8.78a1 1 0 1 0 1.41 0 1 1 0 0 0-1.41 0z"
    />
  </symbol>

  <symbol id="icon-exp-laptop" viewBox="0 0 23.19 17">
    <path
      d="M21.69 17H1.5a1.5 1.5 0 0 1-1.2-2.4l2-2.67a1 1 0 0 0 .2-.6V3a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-10a1 1 0 0 1 0-2h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1v8.33a3 3 0 0 1-.6 1.8L2.5 15h18.38l-.28-.55a1 1 0 1 1 1.79-.89l.61 1.27A1.5 1.5 0 0 1 21.69 17z"
    />
  </symbol>

  <symbol id="icon-exp-location" viewBox="0 0 18 24">
    <path
      d="M9 12a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm.56 17.83a1 1 0 0 0 .27-1.39C2.44 11.48 2 10.67 2 8c0-2.41 1.86-6 7-6s7 3.59 7 6c0 2.21-.19 3-5.16 10.47a1 1 0 0 0 1.66 1.11C17.33 12.34 18 10.94 18 8c0-3.09-2.44-8-9-8S0 4.91 0 8c0 3.34.69 4.47 8.17 15.56a1 1 0 0 0 1.39.27z"
    />
  </symbol>

  <symbol id="icon-exp-notes" viewBox="0 0 18 24">
    <path
      d="M15 24H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v1.28a2 2 0 0 1-1.37 1.9l-2.32.77a1 1 0 1 1-.63-1.9L16 4.28V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1 1 1 0 0 1 2 0 3 3 0 0 1-3 3zm-.68-5.05l2.32-.77a2 2 0 0 0 1.36-1.9V15a1 1 0 0 0-2 0v1.28l-2.32.77a1 1 0 1 0 .63 1.9zm0-6l2.32-.77a2 2 0 0 0 1.36-1.9V9a1 1 0 0 0-2 0v1.28l-2.32.77a1 1 0 1 0 .63 1.9z"
    />
  </symbol>

  <symbol id="icon-exp-price-tag" viewBox="0 0 22.32 23.15">
    <path
      d="M10.07 23.15H10a1 1 0 0 1-.6-1.74L20 10.78a1 1 0 0 0 .29-.78l-.57-7.42-7.4-.58a1 1 0 0 0-.78.29l-9.25 9.25a1 1 0 0 0 0 1.41l5.59 5.59A1 1 0 0 1 6.46 20L.88 14.37a3 3 0 0 1 0-4.24L10.13.88A3 3 0 0 1 12.48 0l8.28.64a1 1 0 0 1 .92.92l.64 8.28a3 3 0 0 1-.87 2.35L10.78 22.85a1 1 0 0 1-.71.3zm7.07-13.73a3 3 0 1 0-2.14.88 3 3 0 0 0 2.14-.88zm-1.41-2.83A1 1 0 1 1 15 6.3a1 1 0 0 1 .73.29z"
    />
  </symbol>

  <symbol id="icon-exp-purse" viewBox="0 0 24 18">
    <path
      d="M19 18H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v1a1 1 0 0 1-2 0V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a1 1 0 0 1 2 0v1a3 3 0 0 1-3 3zm5-8V8a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2v3a1 1 0 0 0 2 0V8h5v2h-2a1 1 0 0 0 0 2h2a2 2 0 0 0 2-2z"
    />
  </symbol>

  <!-- Custom icons set -->
  <symbol id="icon-diplome" viewBox="0 0 17 16">
    <path
      d="M15.7 14.6h-.2V9.1c0-.6-.4-1.2-1-1.3l-2-.4-3.4-2.8c-.1 0-.1-.1-.2-.1v-.7c.3.1.6.2.8.2.3 0 .6-.1.9-.3.4-.2.8-.3 1.2-.3.2 0 .5.1.7.2.1 0 .1.1.2.1.2 0 .4-.2.4-.4V.9s-.5-.6-1.3-.6c-.4 0-.9.2-1.2.3-.3.1-.6.3-.9.3-.2 0-.6-.1-1-.3h-.2c-.2 0-.4.2-.4.4v3.7c-.1-.2-.1-.1-.2-.1L4.6 7.4l-2 .4c-.6.1-1 .7-1 1.3v5.5h-.3c-.2 0-.4.2-.4.4s.2.4.4.4h14.4c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zM8.9 2.9V1.4c.3.1.6.2.8.2.4 0 .8-.2 1.2-.3.3-.2.6-.3.9-.3.2 0 .4.1.5.2v1.5c-.2 0-.3-.1-.5-.1-.5 0-1 .2-1.5.4-.2.1-.5.2-.6.2a2 2 0 0 1-.8-.3zm-1.2 9.7c0-.4.4-.8.8-.8s.8.4.8.8v2H7.7v-2zm.8-1.6c-.9 0-1.6.7-1.6 1.6v2H2.3V9.1c0-.2.2-.4.4-.5l2-.4h.2l3.5-2.9c.1-.1.2 0 .2 0l3.5 2.9 2.2.5c.2 0 .4.3.4.5v5.5h-4.6v-2c0-1-.7-1.7-1.6-1.7z"
    />
  </symbol>

  <symbol id="icon-clock" viewBox="0 0 12 12">
    <path
      d="M6 .8C3.1.8.8 3.1.8 6c0 2.9 2.4 5.2 5.2 5.2 2.9 0 5.2-2.4 5.2-5.2C11.2 3.1 8.9.8 6 .8zm0 9.5c-2.4 0-4.4-2-4.4-4.3s2-4.4 4.4-4.4 4.3 2 4.3 4.4-1.9 4.3-4.3 4.3z"
    />
    <path d="M6.5 5.8V3.6c0-.2-.2-.5-.5-.5s-.4.3-.4.5v2.7L7.3 8h.3c.1 0 .2 0 .3-.1.2-.2.2-.5 0-.6L6.5 5.8z" />
  </symbol>

  <symbol id="icon-share" viewBox="0 0 24 18">
    <path
      d="M21 18a3 3 0 0 1-2.88-2.17L7 12.69A4 4 0 1 1 4 6a4 4 0 0 1 2.76 1.1l6.79-4.67A3 3 0 0 1 16.5 0a3 3 0 0 1 0 6 1 1 0 0 1 0-2 1 1 0 1 0-1-1.16 1.89 1.89 0 0 1-.83 1.25L7.13 9.27a1 1 0 0 1-1.46-.37 2 2 0 1 0 0 2.17 1 1 0 0 1 1.15-.49l11.81 3.29A2 2 0 0 1 20 15.28 1 1 0 1 0 21 14a1 1 0 0 1 0-2 3 3 0 0 1 0 6z"
    />
  </symbol>

  <!-- Social icons (share, profile, etc.) -->
  <symbol id="icon-facebook" viewBox="0 0 42 42">
    <circle cx="21" cy="21" r="21" fill="#3B5998" />
    <path
      fill="#FFF"
      d="M18.11 31.22h3.83v-10.5h2.87l.56-3.29h-3.43v-2.38c0-.76.5-1.57 1.2-1.57h1.96V10.2h-2.4v.01c-3.74.14-4.51 2.31-4.58 4.59v2.63H16.2v3.29h1.91v10.5z"
    />
  </symbol>

  <symbol id="icon-linkedin" viewBox="0 0 42 42">
    <circle cx="21" cy="21" r="21" fill="#007AB9" />
    <path
      fill="#FFF"
      d="M33.3 22.7v8.52h-4.98v-7.95c0-2-.71-3.36-2.52-3.36-1.38 0-2.2.92-2.56 1.8-.13.32-.16.77-.16 1.21v8.3h-4.99s.07-13.47 0-14.86h4.99v2.1l-.03.06h.03v-.05a4.96 4.96 0 0 1 4.5-2.46c3.27 0 5.73 2.13 5.73 6.69zM12.89 9.2c-1.7 0-2.82 1.1-2.82 2.57 0 1.42 1.08 2.56 2.76 2.56h.03c1.74 0 2.82-1.14 2.82-2.56-.04-1.46-1.08-2.57-2.79-2.57zm-2.52 22.02h4.98V16.36h-4.98v14.86z"
    />
  </symbol>

  <symbol id="icon-messenger" viewBox="0 0 26 26">
    <circle cx="13" cy="13" r="13" fill="#0084ff" />
    <path
      fill="#fff"
      d="M13.788 14.79l-1.645-1.765-3.21 1.766 3.53-3.772 1.686 1.765 3.17-1.765-3.531 3.772zm-.642-8.104c-3.568 0-6.46 2.694-6.46 6.018 0 1.894.94 3.584 2.407 4.687v2.295l2.2-1.215a6.9 6.9 0 0 0 1.853.252c3.567 0 6.46-2.695 6.46-6.019s-2.893-6.018-6.46-6.018z"
    />
  </symbol>

  <symbol id="icon-twitter" viewBox="0 0 42 42">
    <circle cx="21" cy="21" r="21" fill="#55ACEE" />
    <path
      fill="#FFF"
      d="M30.62 12.97a6.8 6.8 0 0 1-2.63.93 4.2 4.2 0 0 0-7.14 3.85 11.66 11.66 0 0 1-8.68-4.37 4.21 4.21 0 0 0 1.28 5.56c-.5.04-1.4-.06-1.84-.48-.03 1.47.68 3.41 3.25 4.12-.5.26-1.37.19-1.75.13.13 1.24 1.86 2.86 3.76 2.86-.68.78-3.22 2.2-6.07 1.74a12.65 12.65 0 0 0 6.58 1.87 11.7 11.7 0 0 0 11.78-12.3v-.02-.05-.06a7.26 7.26 0 0 0 2.04-2.16c-.34.2-1.37.57-2.33.66a4.47 4.47 0 0 0 1.75-2.28"
    />
  </symbol>

  <symbol id="icon-whatsapp" viewBox="0 0 26 26">
    <circle cx="13" cy="13" r="13" fill="#3ace01" />
    <path
      fill="#fff"
      d="M17.742 8.575a6.403 6.403 0 0 0-4.558-1.89 6.452 6.452 0 0 0-6.444 6.442 6.43 6.43 0 0 0 .86 3.22l-.914 3.339 3.416-.896c.94.513 2 .784 3.08.784h.002a6.452 6.452 0 0 0 6.444-6.442c0-1.721-.67-3.34-1.886-4.557zm-4.558 9.911h-.002a5.349 5.349 0 0 1-2.726-.746l-.196-.116-2.027.531.54-1.975-.126-.203a5.34 5.34 0 0 1-.82-2.85 5.363 5.363 0 0 1 5.359-5.353 5.32 5.32 0 0 1 3.786 1.57 5.321 5.321 0 0 1 1.568 3.788 5.363 5.363 0 0 1-5.356 5.354zm1.837-4.534c-.147-.053-.255-.08-.362.081a9.374 9.374 0 0 1-.51.631c-.094.107-.188.12-.349.04-.16-.08-.68-.25-1.294-.799a4.85 4.85 0 0 1-.896-1.115c-.094-.16-.01-.248.07-.328.073-.072.161-.188.242-.282.08-.094.107-.161.161-.269.054-.107.027-.201-.013-.282-.04-.08-.363-.872-.497-1.195-.13-.314-.263-.271-.362-.276a6.455 6.455 0 0 0-.309-.006.592.592 0 0 0-.43.202c-.147.16-.563.55-.563 1.343 0 .792.577 1.557.658 1.665.08.107 1.135 1.733 2.75 2.43.385.166.684.265.918.34a2.22 2.22 0 0 0 1.014.063c.31-.046.953-.39 1.087-.766s.134-.698.094-.765-.147-.107-.308-.188c-.162-.08-.953-.47-1.1-.524z"
    />
  </symbol>
</svg>
