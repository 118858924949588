import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { toApplication, toCandidate, toSource, toUtm } from '@ats/models';
import { environment } from '@env/environment';
import { CandidateApplicationResourceInterface } from '@models/candidate-application/candidate-application.resource';
import { Store } from '@ngxs/store';
import { ApplicationsState } from '@store/applications/applications.state';
import { Observable } from 'rxjs';

@Injectable()
export class CandidateApplicationWebservice {
  private readonly baseUrl = `${environment.api.candidate}/v1`;

  constructor(private readonly http: HttpClient, private readonly store: Store) {}

  create(form: FormGroup, jobId: string): Observable<object> {
    const resource = this.formToResource(form, jobId);

    const url = `${this.baseUrl}/candidate/application`;

    return this.http.post(url, resource);
  }

  private formToResource(form: FormGroup, jobId: string): CandidateApplicationResourceInterface {
    const tmpApplication = this.store.selectSnapshot(ApplicationsState.tmpApplication);
    const source = this.store.selectSnapshot(ApplicationsState.sourcePlatform('ats-career'));

    return {
      application: toApplication({
        jobId,
        coverLetter: form.value.presentation,
        resumeFileId: form.value.cv === '' ? null : form.value.cv.id,
        status: form.value.status,
        utmSource: toSource({
          platform: 'Career',
          utm: toUtm({
            campaign: tmpApplication ? tmpApplication.utmSource.utm.campaign : jobId,
            content: tmpApplication ? tmpApplication.utmSource.utm.content : source.utm.content || null,
            medium: tmpApplication ? tmpApplication.utmSource.utm.medium : source.utm.medium || null,
            source: tmpApplication ? tmpApplication.utmSource.utm.source : source.name,
            term: tmpApplication ? tmpApplication.utmSource.utm.term : source.utm.term || null,
          }),
        }),
      }),
      candidate: toCandidate({
        firstName: form.value.firstname,
        lastName: form.value.lastname,
        email: form.value.email,
        phone: form.value.phone === '' ? null : form.value.phone,
        location: form.value.city === '' ? null : form.value.city,
        currentJob: form.value.job === '' ? null : form.value.job,
      }),
    };
  }
}
