import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Optional, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { WizbiiRequest } from '@ssr/types';
import { SetAuthenticatedCompany } from '@store/companies/companies.actions';
import { Init } from '@store/session/session.actions';
import { WINDOW } from '@wizbii/angular-utilities';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export enum SessionStateEnum {
  Init = 'INIT',
  Initialized = 'INITIALIZED',
}

export class SessionStateModel {
  loading: boolean;
  state: SessionStateEnum;
  error: HttpErrorResponse | Error;
  errorMessage: string;
}

const defaultState: SessionStateModel = {
  loading: false,
  state: SessionStateEnum.Init,
  error: null,
  errorMessage: null,
};

const defaultCompany = 'berger-diffusion';

@State<SessionStateModel>({
  name: 'session',
  defaults: defaultState,
})
export class SessionState {
  // tslint:disable:no-identical-functions
  @Selector()
  static state(state: SessionStateModel) {
    return state.state;
  }

  @Selector()
  static isInitialized(state: SessionStateModel) {
    return state.state !== SessionStateEnum.Init;
  }

  @Selector()
  static loading(state: SessionStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: SessionStateModel) {
    return state.error;
  }

  @Selector()
  static errorMessage(state: SessionStateModel) {
    return state.errorMessage;
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(WINDOW) private readonly window: any,
    @Optional() @Inject(REQUEST) private readonly request?: WizbiiRequest
  ) {}

  @Action(Init)
  init(ctx: StateContext<SessionStateModel>) {
    let companyId = defaultCompany;

    if (isPlatformServer(this.platformId) && this.request) {
      companyId = this.request.companyId || defaultCompany;
    } else if (!environment.dev) {
      companyId = this.window.location.hostname.split('.')[0];
    }

    return ctx
      .dispatch([new SetAuthenticatedCompany(companyId)])
      .pipe(switchMap(() => of(ctx.patchState({ state: SessionStateEnum.Initialized }))));
  }
}
