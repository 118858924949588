import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotFoundComponent } from '@features/not-found/not-found.component';

const routes: Routes = [
  {
    path: FeaturesRoutingEnum.Home,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: `${FeaturesRoutingEnum.Job}/${FeaturesRoutingEnum.JobId}`,
    loadChildren: () => import('./job/job.module').then(m => m.JobModule),
  },
  {
    path: FeaturesRoutingEnum.NotFound,
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
    data: { unhandled404: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {}
