import { environment } from '@env/environment';
const ɵ0 = {
    baseUrl: environment.api.recruiter,
}, ɵ1 = {
    baseUrl: environment.api.candidate,
}, ɵ2 = {
    baseUrl: environment.api.file,
    googleStorageUrl: environment.api.googleStorage,
    wizbiiFilesBucket: environment.wizbiiFiles,
};
export class FeaturesModule {
}
export { ɵ0, ɵ1, ɵ2 };
