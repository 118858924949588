var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ApplicationsState_1;
import { HttpErrorResponse } from '@angular/common/http';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { HandleErrors, InitStaticData, SetTmpApplication } from '@store/applications/applications.actions';
import { buildUrl } from '@wizbii/angular-utilities';
import { ApplicationWebservice } from '@wizbii/webservices';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
export class ApplicationStateModel {
}
const defaultState = {
    sourcesPlatforms: null,
    tmpApplication: null,
    error: null,
};
let ApplicationsState = ApplicationsState_1 = class ApplicationsState {
    constructor(applicationWebservice) {
        this.applicationWebservice = applicationWebservice;
    }
    static sourcesPlatforms(state) {
        return state.sourcesPlatforms;
    }
    static tmpApplication(state) {
        return state.tmpApplication;
    }
    static sourcePlatform(name) {
        return createSelector([ApplicationsState_1], (state) => state.sourcesPlatforms.find(source => name.toLowerCase().includes(source.utm.source.toLowerCase())));
    }
    initStaticData(ctx) {
        const { sourcesPlatforms } = ctx.getState();
        if (sourcesPlatforms && sourcesPlatforms.length > 0) {
            return undefined;
        }
        return this.applicationWebservice.getSourcesPlatform().pipe(map(_sourcesPlatforms => of(ctx.patchState({
            sourcesPlatforms: _sourcesPlatforms,
        }))), catchError((err) => {
            if (err instanceof HttpErrorResponse && err.status === 500) {
                return throwError(new Error('Internal Server Error while getting datas'));
            }
            return throwError(err);
        }));
    }
    setTmpApplication(ctx, { application }) {
        ctx.patchState({
            tmpApplication: application && application.utmSource && application.utmSource.utm && application.utmSource.utm.source
                ? application
                : null,
        });
    }
    handleErrors(ctx, { error }) {
        // tslint:disable no-small-switch
        switch (error.status) {
            // The Job doesn't exist so => 404
            case 404: {
                console.error('Not found [Application]');
                ctx.patchState({ error });
                return ctx.dispatch(new Navigate([buildUrl(FeaturesRoutingEnum.NotFound)], undefined, { skipLocationChange: true }));
            }
            default: {
                console.error(`Code ${error.status} => ${error.statusText}`);
                return ctx.patchState({ error });
            }
        }
        // tslint:enable no-small-switch
    }
};
__decorate([
    Action(InitStaticData),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ApplicationsState.prototype, "initStaticData", null);
__decorate([
    Action(SetTmpApplication),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetTmpApplication]),
    __metadata("design:returntype", void 0)
], ApplicationsState.prototype, "setTmpApplication", null);
__decorate([
    Action(HandleErrors),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, HandleErrors]),
    __metadata("design:returntype", void 0)
], ApplicationsState.prototype, "handleErrors", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [ApplicationStateModel]),
    __metadata("design:returntype", void 0)
], ApplicationsState, "sourcesPlatforms", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [ApplicationStateModel]),
    __metadata("design:returntype", void 0)
], ApplicationsState, "tmpApplication", null);
ApplicationsState = ApplicationsState_1 = __decorate([
    State({
        name: 'applications',
        defaults: defaultState,
    }),
    __metadata("design:paramtypes", [ApplicationWebservice])
], ApplicationsState);
export { ApplicationsState };
