import { HttpErrorResponse } from '@angular/common/http';
import { Application } from '@ats/models';

export class InitStaticData {
  static readonly type = '[Application] Init static data';
}

export class SetTmpApplication {
  static readonly type = '[Application] Set Temporary';
  constructor(public application: Application) {}
}

export class HandleErrors {
  static readonly type = '[Application] Handle Errors';
  constructor(public error: HttpErrorResponse) {}
}
